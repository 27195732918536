import React from "react";
import { ReactComponent as FeedbackIcon } from "../../../../assets/icons/Feedback_Icon.svg";
import { triggerFeedbackModal } from "../../../../modules/actions/modals.action";
import { useDispatch } from "react-redux";

import "./FeedbackGuestButton.scss";

const FeedbackGuestButton = () => {
  const dispatchAction = useDispatch();

  //Open feedback modal
  const openFeedbackModal = () => {
    dispatchAction(triggerFeedbackModal());
  };

  return (
    <div className="feedback-form__float-activator" onClick={openFeedbackModal}>
      <div className="float-activator__wrapper">
        <i className="float-activator__float-icon">
          <FeedbackIcon />
        </i>
        Give Feedback / Report an issue
      </div>
    </div>
  );
};

export default FeedbackGuestButton;
