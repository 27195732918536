import { Drawer, Grid, IconButton, MenuItem, Menu } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";

import { getBrandName } from "../../../constants/mapping";
import { logOut } from "../../../modules/actions/login.action";
import { objectHasKeysCheck } from "../../../services/commonMethods";
import { Fragment } from "react";
import { getUserDetails } from "../../../services/browserStorage";
import { requestMe } from "../../../modules/actions/me.action";
import companyLogo from "../../../assets/icons/T&B_logo.png"

function TopNavbar(props) {
  const dispatchActions = useDispatch();
  const user = JSON.parse(getUserDetails());

  return (
    <Fragment>
      <Drawer
        variant="permanent"
        open={true}
        anchor="top"
        classes={{
          paperAnchorTop: `${"top-navbar-collapse"}`,
        }}
      >
        <Grid container>
          <Grid item lg={6} md={4} sm={3} className="text-left">
            <h3 style={{fontSize:"1rem", display:"flex", placeItems:"center", lineHeight:"20px"}} className="brand-navbar">
              <img loading="lazy"  style={{borderRadius:"10px", marginRight:"10px"}}
               width={40} src={companyLogo} alt="company logo" />
              <span> {`TRANSACTION MANAGEMENT`}</span>
               </h3>
          </Grid>
          <Grid item lg={5} md={3} sm={4} className="text-right">
            {user.data?.name ? (
              <h4 style={{fontSize:"1rem"}} className="brand-navbar">
                {`Hi,${user.data?.name}`}
              </h4>
            ) : (
              ""
            )}
          </Grid>
          <Grid item lg={1} md={4} sm={4} className="text-right">
            <IconButton
              aria-label="slide"
              classes={{ root: "slide-btn2 btn-dropdown" }}
              onClick={() => {
                dispatchActions(logOut());
              }}
              style={{ textTransform: "capitalize" }}
            >
              Logout
            </IconButton>
            {/* ) : (
              ""
            )} */}
          </Grid>
        </Grid>
      </Drawer>
    </Fragment>
  );
}
{
}

const mapStateToProps = (state) => ({
  me: state.meReducer.data,
});

const mapDispatchToProps = (dispatch) => ({});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TopNavbar)
);
