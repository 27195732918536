import { LOGIN_SUCCESS, LOG_OUT } from "../../constants/action.constants";

const initialState = {
  loggedIn: false,
};

const loginReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return { loggedIn: true };
    case LOG_OUT:
      return { loggedIn: false };
    default:
      return state;
  }
};

export default loginReducer;
