import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { DEFAULT_SUCCESS_MESSAGE } from "../constants/index";
import { resetShowModal } from "../modules/actions/cancelConfirm.action";
import CancelConfirm from "../pages/components/Modals/CancelConfirm";
import CustomSnackBar from "../pages/components/SnackBar";
import { objectHasKeysCheck } from "../services/commonMethods";

class AppContainer extends Component {
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.successToastObj !== this.props.successToastObj &&
      objectHasKeysCheck(nextProps.successToastObj)
    ) {
      if (
        nextProps.successToastObj &&
        nextProps.successToastObj.dangerouslySetRedirectPath
      ) {
        return this.props.history.push(
          nextProps.successToastObj.dangerouslySetRedirectPath
        );
      }
      //If there is no user path history, then push the route manually.
      if (
        this.props.history.action === "POP" &&
        nextProps.successToastObj.path
      ) {
        return this.props.history.push(nextProps.successToastObj.path);
      } else if (nextProps.successToastObj.path) {
        //Otherwise, go back to the previous screen.
        return this.props.history.goBack();
      }
    }
  }

  render() {
    return (
      <Fragment>
        {this.props.children}
        {objectHasKeysCheck(this.props.successToastObj) ? (
          <CustomSnackBar
            color="primary"
            show={objectHasKeysCheck(this.props.successToastObj)}
            message={
              objectHasKeysCheck(this.props.successToastObj)
                ? this.props.successToastObj.message
                : DEFAULT_SUCCESS_MESSAGE
            }
          />
        ) : (
          ""
        )}

        <CancelConfirm
          open={this.props.showCancelConfirmModal}
          onClose={() => this.props.resetShowModal()}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  successToastObj: state.toastReducer.successToastObj,
  showCancelConfirmModal: state.cancelConfirmReducer.showModal,
});

const mapDispatchToProps = (dispatch) => ({
  resetShowModal: () => dispatch(resetShowModal()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppContainer));
