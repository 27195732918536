import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import AuthRoutes from "./routeManagement/AuthRoutes";
import UnAuthRoutes from "./routeManagement/UnAuthRoutes";
import { getAccessToken, getUserDetails } from "./services/browserStorage";
import axios from "axios";
import { CircularProgress } from "@material-ui/core";
import { errorToast } from "./modules/actions/toast.action";
import { logOut, loginSuccess } from "./modules/actions/login.action";
import CustomSnackBar from "./pages/components/SnackBar";
import {
  disableNumberInputScrollChange,
  getComponentTexts,
  objectHasKeysCheck,
  safelySubmitFormOnKeyPress,
} from "./services/commonMethods";
import {
  API_STATUS_CODES,
  DEFAULT_ERROR_MESSAGE,
  ENUMS,
} from "./constants/index";
import { checkTokenSessionTime } from "./modules/actions/regenerateToken.action";
import LicenseExpired from "./pages/components/LicenseExpired/LicenseExpired";
import FeedbackModal from "./pages/containers/FeedbackModal/FeedbackModal";
import ConfigAppLoginDetails from "./pages/components/Modals/ConfigAppLoginDetails/ConfigAppLoginDetails";
import { triggerConfigAppPasswordModal } from "./modules/actions/modals.action";
import { catchApiErrors } from "./services/Sentry";

export const LoaderContext = React.createContext(false);
export const CancelApiSourcesContext = React.createContext([]);

class Gatekeeper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authToken:
        getAccessToken() &&
        getUserDetails() &&
        !window.location.href.match("/forgot-password") &&
        !window.location.href.match("/reset-password")
          ? true
          : false,
      showLoader: false,
      freezeScreen: false,
      cancelApiSources: [],
    };

    if (this.state.authToken) {
      this.props.loginSuccess();
    }
  }

  //Enables user interaction with the content of the application.
  enableUserInteraction = function () {
    //Latch to avoid element not found error

    document.body.style.pointerEvents = "auto";
  };

  //Disables user interaction with the content of the application.
  disableUserInteraction = function () {
    //Latch to avoid element not found error
    document.body.style.pointerEvents = "none";
  };

  componentWillMount() {
    const sentryMessages = getComponentTexts("sentry");
    //prevent opening up of select form on hit of enter button.
    safelySubmitFormOnKeyPress();
    disableNumberInputScrollChange();
    //keeps record of total api calls requested.
    let totalApiRequested = 0;
    let totalApiResponded = 0;

    const getResponseFromError = function (error) {
      try {
        return error.response.data;
      } catch {
        //If there is no repsonse then it means there is Network error
        return sentryMessages.network_error_message;
      }
    };

    axios.interceptors.response.use(
      (response) => {
        //If total api that were requested are fulfilled, only then change the loader
        if (++totalApiResponded === totalApiRequested) {
          this.enableUserInteraction();
          this.setState({ showLoader: false });
        }
        return response;
      },
      (error) => {
        let apiResponse = getResponseFromError(error);
        this.props.errorToast(error.response);

        //If total api that were requested are fulfilled, only then change the loader
        if (++totalApiResponded === totalApiRequested) {
          this.enableUserInteraction();
          this.setState({ showLoader: false });
          document.body.style.pointerEvents = "auto";
        }

        if (
          error &&
          error.response &&
          error.response.status === 401 &&
          error.response.data.message.toLowerCase() === "token expired."
        ) {
          this.props.logout();
        } else if (
          error.response &&
          error.response.status === API_STATUS_CODES.LICENSE_EXPIRED
        ) {
          //If status code is 402, then freeze the screen.
          //Freezes the screen
          this.props.logout();
          //Show license modal.
          this.setState({ freezeScreen: true });
        }

        catchApiErrors(error, apiResponse);
        return error;
      }
    );
    axios.interceptors.request.use(
      (request) => {
        totalApiRequested++;
        this.disableUserInteraction();
        //pushes api source to the state & enables loader.
        this.setState(({ cancelApiSources }) => ({
          showLoader: true,
          cancelApiSources: [...cancelApiSources, request.source],
        }));
        return request;
      },
      (error) => {
        let apiResponse = getResponseFromError(error);
        catchApiErrors(error, apiResponse);
        return error;
      }
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.loggedIn !== this.props.loggedIn) {
      this.setState(
        {
          authToken:
            window.location.href.match("/forgot-password") ||
            window.location.href.match("/reset-password")
              ? false
              : nextProps.loggedIn,
        }
        // () => {
        //   if (this.state.authToken) {
        //     this.props.checkTokenSessionTime();
        //   }
        // }
      );
    }
  }

  closeLicenseExpired = () => {
    this.setState({ freezeScreen: false });
  };

  render() {
    //Function to clear api requests sources and to cancel all ongoing api requests.
    const cancelApiRequests = () => {
      //Cancel each api.
      this.state.cancelApiSources.forEach((source) => {
        if (source) {
          source.cancel();
        }
      });
      //Clear api sources.
      return this.setState({ cancelApiSources: [] });
    };

    return (
      <Fragment>
        <LoaderContext.Provider value={this.state.showLoader}>
          <CancelApiSourcesContext.Provider value={cancelApiRequests}>
            {this.state.freezeScreen && (
              <LicenseExpired
                onClose={this.closeLicenseExpired}
                checkUserRole={this.props.me.Role?.role_code}
              />
            )}
            {this.state.authToken ? <AuthRoutes /> : <UnAuthRoutes />}
            {this.state.showLoader && (
              <CircularProgress
                classes={{
                  root: "circular-loader",
                  colorPrimary: "circular-loader-color",
                }}
              />
            )}
          </CancelApiSourcesContext.Provider>
        </LoaderContext.Provider>
        <CustomSnackBar
          color="danger"
          show={objectHasKeysCheck(this.props.errorToastObj)}
          message={
            objectHasKeysCheck(this.props.errorToastObj)
              ? this.props.errorToastObj.data &&
                this.props.errorToastObj.data.message
                ? this.props.errorToastObj.data.message
                : DEFAULT_ERROR_MESSAGE
              : DEFAULT_ERROR_MESSAGE
          }
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  loggedIn: state.loginReducer.loggedIn,
  errorToastObj: state.toastReducer.errorToastObj,
  // showFeedbackModal: state.modalsReducer.showFeedbackModal,
  // showConfigAppPasswordModal: state.modalsReducer.showConfigAppPasswordModal,
});

const mapDispatchToProps = (dispatch) => ({
  errorToast: (obj) => dispatch(errorToast(obj)),
  logout: () => dispatch(logOut()),
  loginSuccess: () => dispatch(loginSuccess()),
  checkTokenSessionTime: () => dispatch(checkTokenSessionTime()),
  // handleConfigAppPasswordModal: () => dispatch(triggerConfigAppPasswordModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Gatekeeper);
