//Unique keys for every api call to distinguish every api url & its action.
export const API_ROUTES_KEY = {
  GET_CUSTOMER_LIST: "GET_CUSTOMER_LIST",
  GET_CUSTOMER: "GET_CUSTOMER",
  // list active and discontinued products of inventory
  GET_FILTER_CUSTOMER_LIST: "GET_FILTER_CUSTOMER_LIST",
  GET_REFUND_REASON_LIST: "GET_REFUND_REASON_LIST",
  GET_REFUNDABLE_ITEMS_LIST: "GET_REFUNDABLE_ITEMS_LIST",
  INITIATE_REFUND: "INITIATE_REFUND",
  GET_USER_LIST: "GET_USER_LIST",

  USER_ROLES: "USER_ROLES",
  GET_PRODUCT: "GET_PRODUCT",
};

//End points of the api.
export const API_ENDPOINTS = {
  CUSTOMER_MANAGEMENT: "/customer-dashboard",
  SALES_MANAGEMENT: "/sales",
};

//Particular end-points of api calls
export const API_ROUTES = {
  [API_ROUTES_KEY.GET_CUSTOMER_LIST]: API_ENDPOINTS.SALES_MANAGEMENT,
  [API_ROUTES_KEY.GET_CUSTOMER]: API_ENDPOINTS.CUSTOMER_MANAGEMENT,
};
