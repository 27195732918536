import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools as compose } from "redux-devtools-extension";
import rootReducer from "../reducers/index";
// Logger with default options
// import logger from "redux-logger";
const middlewares = [thunkMiddleware];

const configureStore = (initialState = {}) => {
  return createStore(
    rootReducer,
    initialState,
    compose(applyMiddleware(...middlewares))
  );
};

export default configureStore;
