import React from "react";
import ModalContainer from "../AddContainer";
import "./ConfigAppLoginDetails.scss";

//Modal to show password for config app guest login.
const ConfigAppLoginDetails = ({ onClose, password }) => {
  return (
    <ModalContainer
      title={"Get Login Details on Config App"}
      open={true}
      onClose={onClose}
      className={"add-container-modal config-app-details"}
    >
      <div className="config-app-details__container">
        <div className="text-center config-app-details__text">
          Login through guest user to configure Network and location details of
          your Vending machine on Config App.
        </div>
        <div className="config-app-details__password-wrapper text-center">
          Password :{" "}
          <span className="config-app-details__password-text">{password}</span>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfigAppLoginDetails;
