import { postAuthApi } from "../../webApis/apiResource";
import { webApiCheck } from "../../services/commonMethods";
import { LOGIN_SUCCESS, LOG_OUT } from "../../constants/action.constants";
import {
  setAccessToken,
  removeAccessToken,
  setUserDetails,
  setTokenExpiry,
  setUserPermissions,
} from "../../services/browserStorage";

export const requestLogin = (data) => {
  return async (dispatch) => {
    // const response = await postAuthApi("/user-management", data);
    const response = await postAuthApi("/login", data);
    // localStorage.setItem("name", "yogesh");
    // console.log("data:",response)
    if (webApiCheck(response)) {
      const {
        data: { data },
      } = response;
      // console.log("data.Role.permissions:",data.Role.permissions)
      setTokenExpiry(data.expires_in);
      setAccessToken(data.token);
      setUserDetails(data);
      // setUserPermissions(data.Role.permissions);
      dispatch(loginSuccess());
    }
  };
};

export const loginSuccess = () => {
  return {
    type: LOGIN_SUCCESS,
  };
};

export const logOut = () => {
  removeAccessToken();
  localStorage.clear();
  return {
    type: LOG_OUT,
  };
};
