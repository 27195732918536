import React, { Component } from "react";
import { Provider as ReduxProvider } from "react-redux";
import { CookiesProvider } from "react-cookie";
import "bootstrap/dist/css/bootstrap.css";
import "./scss/main.scss";
import "./services/prototypes";
import GateKeeper from "./gatekeeper";
import configureStore from "./modules/store";
import ErrorBoundary from "./pages/components/ErrorBoundary/ErrorBoundary";

const reduxStore = configureStore(window.REDUX_INITIAL_DATA);

class App extends Component {
  render() {
    return (
      <ErrorBoundary>
        <ReduxProvider store={reduxStore}>
          <CookiesProvider>
            <div className="App">
              <GateKeeper />
            </div>
          </CookiesProvider>
        </ReduxProvider>
      </ErrorBoundary>
    );
  }
}

export default App;
