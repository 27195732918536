import { removeNullFieldsFromJson } from "../services/commonMethods";
import { API_ROUTES_KEY } from "./apiRoutes";

//Payload action for the apis.
export const API_ACTIONS = {
  // [API_ROUTES_KEY.ISSUE_REPORTER]: "report_issue",
  // [API_ROUTES_KEY.GET_USER_LIST]: "list.users",
  // [API_ROUTES_KEY.LIST_ZONES]: "list.zones",
  // [API_ROUTES_KEY.DELETE_ZONES]: "delete.zone",
  // [API_ROUTES_KEY.GET_PRODUCT]: "get.product",
  // [API_ROUTES_KEY.LIST_WAREHOUSES]: "list.warehouses",
  // [API_ROUTES_KEY.GET_VENDING_MACHINE]: "get_detailed.vm",
  // [API_ROUTES_KEY.GET_STORE_INVENTORY]: "list.store_inventory",
  // [API_ROUTES_KEY.GET_WAREHOUSE_INVENTORY]: "list.inventory",
  // [API_ROUTES_KEY.CREATE_SAVED_REPORT]: "create.saved_report",
  // [API_ROUTES_KEY.GET_SAVED_SALES_TEMPLATE]: "list.saved_report",
  // [API_ROUTES_KEY.GET_SAVED_SALES_REPORT]: "get.saved_report",
  // [API_ROUTES_KEY.GET_DEFAULT_SALES_TEMPLATE]: "list.default_saved_report",
  // [API_ROUTES_KEY.GET_PRODUCT_PACKAGING_LIST]: "list.packaging",
  // [API_ROUTES_KEY.GET_PRODUCT_STATUS_LIST]: "list.product_statuses",
  // [API_ROUTES_KEY.GET_PRODUCT_SHELF_LIFE_LIST]: "list.shelf_life",
  // [API_ROUTES_KEY.LIST_SHIPPING_WAREHOUSES]: "shipping.list.warehouses",
  // [API_ROUTES_KEY.DELETE_WAREHOUSE]: "delete.warehouse",
  // [API_ROUTES_KEY.GET_USER_PRODUCT_CATEGORY_LIST]: "list.product_categories",
  // [API_ROUTES_KEY.GET_PRODUCT_UNIT_LIST]: "list.product_units",
  // [API_ROUTES_KEY.GET_PRODUCT_BRAND_LIST]: "list.brands",
  // [API_ROUTES_KEY.DELETE_PRODUCT_CATAGORY]: "delete.product_category",
  // [API_ROUTES_KEY.DOWNLOAD_TRANSACTIONS_HISTORY]: "download.orders",
  // [API_ROUTES_KEY.GET_CUSTOMER_LIST]: "list.customers",
  [API_ROUTES_KEY.GET_CUSTOMER_LIST]: "list.orders",
  [API_ROUTES_KEY.GET_CUSTOMER]: "list.transactions",
  [API_ROUTES_KEY.GET_FILTER_CUSTOMER_LIST]: "search.customers",

  [API_ROUTES_KEY.GET_CUSTOMER_TRANSACTION_LIST]: "list.transactions",
  // [API_ROUTES_KEY.GET_PRODUCT_LIST]: "list.products",
  // [API_ROUTES_KEY.CREATE_PURCHASE_ORDER]: "create.purchase_order",
  // [API_ROUTES_KEY.GET_PURCHASE_ORDER_LIST]: "list.purchase_orders",
  // [API_ROUTES_KEY.GET_PURCHASE_ORDER]: "get.purchase_order",
  // [API_ROUTES_KEY.UPDATE_PURCHASE_ORDER]: "update.purchase_order",
  // [API_ROUTES_KEY.MANAGE_PURCHASE_ORDER]: "manage.purchase_order",
  // [API_ROUTES_KEY.DELETE_PURCHASE_ORDER]: "delete.purchase_order",
  // [API_ROUTES_KEY.GET_WAREHOUSE_LIST]: "list.warehouses",

  // [API_ROUTES_KEY.GET_STORE_MANAGER_LIST]: "list.store_manager",
  // [API_ROUTES_KEY.CREATE_STORE_MANAGER]: "create.store_manager",
  // [API_ROUTES_KEY.UPDATE_STORE_MANAGER]: "update.store_manager",
  // [API_ROUTES_KEY.DELETE_STORE_MANAGER]: "delete.store_manager",
  // [API_ROUTES_KEY.GET_STORE_MANAGER]: "get.store_manager",
  // [API_ROUTES_KEY.GET_STORE_LIST]: "list.stores",
  // [API_ROUTES_KEY.UPDATE_STORE_MANAGER]: "update.store_manager",
  // [API_ROUTES_KEY.DELETE_STORE_MANAGER]: "delete.store_manager",
  // [API_ROUTES_KEY.GET_VM_TEMPLATE_LIST]: "list.vm_template",
  // [API_ROUTES_KEY.CREATE_VM_TEMPLATE]: "create.vm_template",
  // [API_ROUTES_KEY.GET_VM_TEMPLATE]: "get.vm_template",
  // [API_ROUTES_KEY.UPDATE_VM_TEMPLATE]: "update.vm_template",
  // [API_ROUTES_KEY.DELETE_VM_TEMPLATE]: "delete.vm_template",
  // [API_ROUTES_KEY.GET_STORE_MANAGER_BY_STORE_ID]:
  //   "get.store_manager_by_store_id",
  // [API_ROUTES_KEY.GET_BULK_REFILL_LIST]: "list.bulk_refill",
  // [API_ROUTES_KEY.GET_BULK_REFILL]: "get.bulk_refill",
  // [API_ROUTES_KEY.CREATE_BULK_REFILL]: "create.bulk_refill",
  // [API_ROUTES_KEY.UPDATE_BULK_REFILL]: "update.bulk_refill",
  // [API_ROUTES_KEY.DELETE_BULK_REFILL]: "delete.bulk_refill",
  // [API_ROUTES_KEY.CREATE_REFILL]: "create.refill",
  // [API_ROUTES_KEY.UPDATE_REFILL]: "update.refill",
  // [API_ROUTES_KEY.ASSIGN_DP_TO_REFILL_IN_BULK_REFILL]: "update.delivery_person",
  // [API_ROUTES_KEY.GET_DELIVERY_PERSON_LIST]: "list.delivery_person",
  // [API_ROUTES_KEY.GET_STORE_MANAGER_AND_DELIVERY_PERSON_LIST]:
  //   "list.store_manager_and_delivery_person",
  // [API_ROUTES_KEY.CREATE_SETTINGS_TEMPLATE]: "create.settings_template",
  // [API_ROUTES_KEY.UPDATE_SETTINGS_TEMPLATE]: "update.settings_template",
  // [API_ROUTES_KEY.GET_SETTINGS_TEMPLATE_LIST]: "list.settings_template",
  // [API_ROUTES_KEY.GET_SETTINGS_TEMPLATE]: "get.settings_template",
  // [API_ROUTES_KEY.DELETE_SETTINGS_TEMPLATE]: "delete.settings_template",
  // [API_ROUTES_KEY.GET_CARRIER_BOARD_LIST]: "list.vendify_carrier_board",
  // [API_ROUTES_KEY.GET_VM_BRAND_MODEL_LIST]:
  //   "list.vending_machine_brand_and_model",
  // [API_ROUTES_KEY.GET_VM_TYPE_LIST]: "list.vending_machine_type",
  // [API_ROUTES_KEY.GET_NETWORK_DEVICE_TYPE_LIST]: "list.network_device_types",
  // [API_ROUTES_KEY.UNLOCK_VM]: "unlock.vm",
  // [API_ROUTES_KEY.GET_TRANSACTION_REPORT_COLUMNS]:
  //   "get.transaction_report_columns",
  // [API_ROUTES_KEY.GET_USER_SAVED_COLUMNS]: "get.user_saved_report_columns",
  // [API_ROUTES_KEY.BLOCK_UNBLOCK_VM_COLUMN]: "update.vm_motor_block_status",
  // [API_ROUTES_KEY.GET_FINANCE_REPORT]: "download.finance_report",
  // [API_ROUTES_KEY.GET_REFUND_REASON_LIST]: "list.refund_reason",
  // [API_ROUTES_KEY.GET_REFUNDABLE_ITEMS_LIST]: "get.refund_item_list",
  // [API_ROUTES_KEY.INITIATE_REFUND]: "initiate_refund.order",
  // [API_ROUTES_KEY.DELETE_USER]: "delete.user",
  // [API_ROUTES_KEY.FRANCHISE_LIST]: "list.franchises",
  // [API_ROUTES_KEY.USER_ROLES]: "list.roles",
  // [API_ROUTES_KEY.DELETE_BRAND]: "delete.brand",
  // [API_ROUTES_KEY.DELETE_PRODUCT_UNIT]: "delete.product_unit",
  // [API_ROUTES_KEY.DELETE_PRODUCT_PACKAGING]: "delete.packaging",
  // [API_ROUTES_KEY.LIST_DELIVERY_PARTNERS]: "list.delivery_partners",
  // [API_ROUTES_KEY.DELETE_DELIVERY_PARTNER]: "delete.delivery_partner",
  // [API_ROUTES_KEY.DELETE_FRANCHISE]: "delete.franchise",
  // [API_ROUTES_KEY.DELETE_STORE]: "delete.store",
  // [API_ROUTES_KEY.GET_STATES_LIST]: "list.states",
  // [API_ROUTES_KEY.GET_STORE]: "get.store",
  // [API_ROUTES_KEY.GET_STATE]: "get.state",
  // [API_ROUTES_KEY.SHIPPING_LIST]: "list.shippings",
  // [API_ROUTES_KEY.DELETE_SHIPPING]: "delete.shipping",
  // [API_ROUTES_KEY.DELETE_DELIVERY_PERSON]: "delete.delivery_person",
  // [API_ROUTES_KEY.GET_VENDING_MACHINE_LIST]: "list.available_vms",
  // [API_ROUTES_KEY.GET_VENDING_MACHINE_CONFIGURED_LIST]: "list.configured_vms",
  // [API_ROUTES_KEY.UPDATE_VM_STATUS]: "update_status.vm",
  // [API_ROUTES_KEY.DELETE_VM]: "elete.vm",
  // [API_ROUTES_KEY.ORDER_LIST]: "list.orders",
  // [API_ROUTES_KEY.LIST_APPLICABLE_STATES]: "list.applicable_states",
  // [API_ROUTES_KEY.LIST_APPLICABLE_CITIES]: "list.applicable_cities",
  // [API_ROUTES_KEY.LIST_DISCOUNT]: "list.discounts",
};

//Function returns payload data in required format.
export const getPayloadData = (
  apiUniqueKey,
  removeNullFields = false,
  restParameters = {}
) => {
  let action = API_ACTIONS[apiUniqueKey];
  //data to be returned.
  if (removeNullFields) {
    removeNullFieldsFromJson(restParameters);
  }

  return {
    payload: {
      action,
      ...restParameters,
    },
  };
};
