// Dashboard
import DashboardIcon from "../assets/icons/dashboard.svg";
import DashboardActiveIcon from "../assets/icons/dashboard_orange.svg";

// Permissions
import { PERMISSIONS } from "../constants/index";

import PurchaseOrderManagementIcon from "../assets/icons/order_managemnt.svg";
import PurchaseOrderManagementActiveIcon from "../assets/icons/order_managemnt_orange.svg";
import { APP_ROUTES } from "../constants/appRoutes";
//Supplier Management

import React from "react";
const Dashboard = React.lazy(() => import("../pages/containers/Dashboard"));
const CustomerView = React.lazy(() =>
  import("../pages/containers/CustomerView/CustomerView")
);
const TransactionEmailReport = React.lazy(() =>
  import("../pages/containers/Transaction_Report/TransactionEmailReport")
);
const ROUTES = [
  {
    title: "Customer Dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
    component: Dashboard,
    route: "/dashboard",
    sidebar: false,
    permissions: [PERMISSIONS.PERM_VIEW_PURCHASE_ORDER],
  },

  // {
  //   title: "Customer Overview",
  //   component: TransactionEmailReport,
  //   route: "/customer/:id",
  //   sidebar: false,
  // },
  {
    title: "Customer Overview",
    component: CustomerView,
    route: "/order/:id",
    sidebar: false,
  },
];

export default ROUTES;
