import { getFullRouteWithQueryString } from "../services/commonMethods";

//Routes of the application.
export const APP_ROUTES = {
  HOME: "/",
  DASHBOARD: "/dashboard",
  CUSTOMER_LIST: "/customers",
  ORDER_VIEW: "/order/:id",
  NOTIFICATIONS: "/notifications",
};

//Routes with inline query value. Example : zone/15 , warehouse/15
export const ROUTES_WITH_INLINE_PARAMS = {
  VIEW_CUSTOMER: (id) => APP_ROUTES.ORDER_VIEW.replace(":id", id),
};
