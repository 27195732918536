import React from "react";
import { Component } from "react";
import { ValidationContext } from "./FormValidator";

class ValidationMessage extends Component {
  static contextType = ValidationContext;
  render() {
    //fallbackErrorElement helps in rendering own customized html element if there is no validation error in image picker
    const { errorMessage, fallbackErrorElement } = this.props;
    return (
      <React.Fragment>
        {(this.context &&
          this.context.errors &&
          this.context.errors[this.props.name] &&
          this.context.formSubmitted) ||
        this.context.liveChanges
          ? this.context.errors[this.props.name]
              .removeDuplicates()
              .map((error, index) => (
                <span className="text-danger error-msg" key={index}>
                  {error}
                </span>
              ))
          : fallbackErrorElement || (
              <span className="text-danger error-msg">{errorMessage}</span>
            )}
      </React.Fragment>
    );
  }
}

export default ValidationMessage;
