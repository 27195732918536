import { setAccessToken, setTokenExpiry } from "../../services/browserStorage";
import { removeOnlyAccessToken } from "../../services/browserStorage";
import { getAccessToken } from "../../services/browserStorage";
import { postDashboardWebApi } from "../../webApis/apiResource";
import { webApiCheck } from "../../services/commonMethods";
import { getTokenExpiry } from "../../services/browserStorage";

var regenrateTimeout;

export const regenerateToken = () => {
  return async (dispatch) => {
    const response = await postDashboardWebApi(
      getAccessToken(),
      `/user-management`,
      {
        payload: {
          action: "refresh_token",
        },
      }
    );
    if (webApiCheck(response)) {
      removeOnlyAccessToken();
      setAccessToken(response.data.refresh_token);
      setTokenExpiry(response.data.expires_at);
      dispatch(checkTokenSessionTime());
    }
  };
};

export const checkTokenSessionTime = () => {
  return (dispatch) => {
    if (
      getAccessToken() !== undefined &&
      getAccessToken() !== "undefined" &&
      getAccessToken() !== null &&
      getAccessToken() !== "null"
    ) {
      const expiryTime = getTokenExpiry();
      const remainingTime = expiryTime - (new Date().getTime() + 20000);
      regenrateTimeout = setTimeout(() => {
        dispatch(regenerateToken());
        clearTimeoutSessionTime();
      }, remainingTime);
    }
  };
};

export const clearTimeoutSessionTime = () => {
  if (regenrateTimeout) {
    clearTimeout(regenrateTimeout);
  }
};
