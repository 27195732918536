import {
  webapiGet,
  webapiPut,
  webapiPost,
  webapiAuthPost,
  webapiDelete,
  webapiMultipartPost,
  webapiMultipartPut,
} from "./core";

export function fetchWebApi(accessToken, url) {
  return webapiGet(accessToken, url).request;
}

export function putDashboardWebApi(accessToken, url, bodyObject) {
  return webapiPut(accessToken, url, bodyObject).request;
}

export function putDashboardMultipartWebApi(accessToken, url, bodyObject) {
  return webapiMultipartPut(accessToken, url, bodyObject).request;
}

export function postDashboardWebApi(accessToken, url, bodyObject) {
  return webapiPost(accessToken, url, bodyObject).request;
}

export function postDashboardMultipartWebApi(accessToken, url, bodyObject) {
  return webapiMultipartPost(accessToken, url, bodyObject).request;
}

export function postAuthApi(url, bodyObject) {
  return webapiAuthPost(url, bodyObject).request;
}

export function deleteWebApi(accessToken, url, bodyObject) {
  return webapiDelete(accessToken, url, bodyObject).request;
}
