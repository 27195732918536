/* eslint-disable no-extend-native */
//Convert array of json into comma separated string.
Array.prototype.getCommaSeperatedString = function (field) {
  let str = "";
  this.map(
    (element, index) => (str += (index > 0 ? "," : "") + element[field])
  );
  return str;
};

//Returns date in YY-MM-DD format as it is required by input[type="date"].
Date.prototype.getInputDateFormat = function () {
  let month =
    this.getMonth() + 1 < 10 ? `0${this.getMonth() + 1}` : this.getMonth() + 1;
  let date = this.getDate() < 10 ? `0${this.getDate()}` : this.getDate();

  return this.getFullYear() + "-" + month.toString() + "-" + date;
};

//Returns dateTime in YY-MM-DD T HH:MM format as it is required by input[type="date-time"].
Date.prototype.getInputDateTimeFormat = function () {
  let date = this.getInputDateFormat();
  let hours = this.getHours() < 10 ? `0${this.getHours()}` : this.getHours();
  let minutes =
    this.getMinutes() < 10 ? `0${this.getMinutes()}` : this.getMinutes();

  return `${date}T${hours}:${minutes}`;
};

//Checks if the date has expires.
Date.prototype.checkIfExpires = function () {
  let diff = this.getTime() - new Date().getTime();

  return diff < 0;
};

//Calculation duration between two time intervals
Date.prototype.calculateDuration = function (endDate) {
  if (new Date(endDate).getTime() - this.getTime() < 0) {
    return { text: "Expired", number: 0, expandedText: "Expired" };
  }

  let returnData = (text, expandedText) => {
    return {
      text,
      expandedText,
      number: new Date(endDate).getTime() - this.getTime(),
    };
  };
  let diffInMilliSeconds =
    Math.abs(new Date(endDate).getTime() - this.getTime()) / 1000;

  // calculate days
  let days = Math.floor(diffInMilliSeconds / 86400);
  diffInMilliSeconds -= days * 86400;

  // calculate hours
  const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
  diffInMilliSeconds -= hours * 3600;

  // calculate minutes
  const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
  diffInMilliSeconds -= minutes * 60;

  let difference = "",
    expandedDifference = "",
    weeks = 0,
    months = 0,
    years = 0;

  if (days >= 30) {
    months = Math.floor(days / 30);
    days = days - months * 30;
  }
  if (months >= 12) {
    years = Math.floor(months / 12);
    months = months - years * 12;
  }

  if (days >= 7) {
    weeks = Math.floor(days / 7);
    days = days - weeks * 7;
  }

  if (years > 0) {
    difference += `${years} y `;
    expandedDifference += years === 1 ? `${years} Year ` : `${years} Years `;
  }

  if (months > 0 || years > 0) {
    difference += `${months} m `;
    expandedDifference +=
      months === 1 ? `${months} Month ` : `${months} Months `;

    //Only show months if month exist.
    return returnData(difference, expandedDifference);
  }

  if (weeks > 0) {
    difference += `${weeks} w `;
    expandedDifference += weeks === 1 ? `${weeks} Week ` : `${weeks} Weeks `;
  }

  if (days > 0) {
    difference += `${days} d `;
    expandedDifference += days === 1 ? `${days} Day ` : `${days} Days `;

    //Only show weeks and days if weeks exist
    if (weeks > 0) {
      return returnData(difference, expandedDifference);
    }
  }

  if (hours > 0) {
    difference += hours === 1 ? `${hours} h ` : `${hours} h `;
    expandedDifference += hours === 1 ? `${hours} Hour ` : `${hours} Hours `;
  }

  if (minutes > 0 || expandedDifference === "") {
    difference += minutes === 1 ? `${minutes} m` : `${minutes} m`;
    expandedDifference +=
      minutes === 1 ? `${minutes} Minute` : `${minutes} Minutes`;
  }

  return returnData(difference, expandedDifference);
};

//Checks if any key with a value exists
Array.prototype.checkIfKeyValueExists = function (key, value) {
  return this.some((ele) => ele[key] === value);
};

// check no special character is allowed in strings
String.prototype.isWithoutSpecialChars = function () {
  return new RegExp(/^[A-Za-z0-9 ]+$/).test(this);
};

//filters an array with no duplicates
Array.prototype.removeDuplicates = function () {
  return [...new Set(this)];
};

//Deletes a particular element.
Array.prototype.deleteIndex = function (remIndex) {
  return this.splice(remIndex, 1);
};

Array.prototype.getElementByParam = function (param, value) {
  return this.filter((ele) => ele[param] === value)[0];
};

String.prototype.hasExtension = function (extension) {
  //4 === characters in .csv
  let lastFourChars = this.substr(this.length - 4);
  return lastFourChars === extension;
};

//returns nth occurrence of a character in a string.
String.prototype.nthIndexOf = function (pattern, n) {
  //returns -1 if not found.
  var i = -1;

  while (n-- && i++ < this.length) {
    i = this.indexOf(pattern, i);
    if (i < 0) break;
  }

  return i;
};

//Normalize special character string into normal string.
String.prototype.normalizeMe = function () {
  return this.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
};
