import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CrossIconLink from "../../../assets/icons/cross_icon_link.svg";

export default function AddContainer(props) {
  const [show, setShow] = useState(false);
  const { open, onClose, title, className, disableToolbar } = props;
  const [disableBackdropClick, setDisableBackdropClick] = useState(false);

  useEffect(() => {
    setShow(open);
  }, [open]);

  const handleOnChange = (ev) => {
    setDisableBackdropClick(true);
  };

  return (
    <Dialog
      open={show}
      onClose={onClose}
      classes={{ paper: className || "add-container-modal" }}
      disableBackdropClick={disableBackdropClick}
    >
      {!disableToolbar && (
        <DialogTitle className="add-container-title">
          {title}
          <img
            src={CrossIconLink}
            alt="Close Icon Image"
            className="float-right cross-icon-link"
            onClick={onClose}
          />
        </DialogTitle>
      )}
      <DialogContent
        className="add-container-content"
        onChange={(ev) => handleOnChange(ev)}
      >
        {props.children}
      </DialogContent>
    </Dialog>
  );
}
