import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { ReactComponent as CrossIcon } from "../../../assets/icons/cross_icon_link.svg";

const LicenseExpired = (props) => {
  // to check user role and show expired message based on user role.
  const { checkUserRole, onClose } = props;
  useEffect(() => {
    //disables user activity on screen.
    document.body.style.pointerEvents = "auto";
  }, []);

  return (
    <Dialog open={true} classes={{ paper: "license-expired-modal" }}>
      <DialogTitle className="text-center title">
        <div className="license-expired-modal__title">
          <h2>License Expired</h2>
          <i onClick={onClose} className="license-expired-modal__icon">
            <CrossIcon />
          </i>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="licence-expired-message">
          Your License has expired, Please contact your{" "}
          <span>
            {checkUserRole === "ROLE_COMPANY_ADMIN"
              ? "Integration Admin or Integration Partner"
              : "Company Admin"}
          </span>{" "}
          to renew the license.
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default LicenseExpired;
