import React from "react";
import AppCrashImage from "../../../assets/images/AppCrash.png";
import AppCrashImage2 from "../../../assets/images/AppCrash2.png";
import "./ErrorBoundary.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    let images = [AppCrashImage, AppCrashImage2];
    let image = images[Math.floor(Math.random() * 2)];

    this.state = { hasError: false, image };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  render() {
    const reloadPage = function () {
      window.location.reload();
    };

    if (this.state.hasError) {
      return (
        <div className="app-crashed">
          <div className="app-crash__image">
            <img src={this.state.image} alt="App crashed Image" />
          </div>
          <div className="app-crash__description">
            <h2>Oops!</h2>
            <p className="text-muted">
              Something went wrong, please try again in sometime.
            </p>
            <button onClick={reloadPage}>Try Again</button>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
