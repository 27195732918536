import { Cookies } from "react-cookie";
import { BROWSER_STORAGE } from "../constants/index";
const cookies = new Cookies();

export const setAccessToken = (token) => {
  cookies.set(BROWSER_STORAGE.ACCESS_TOKEN, token, {
    path: "/",
    maxAge: BROWSER_STORAGE.COOKIE_EXPIRY_TIME,
  });
};

export const setTokenExpiry = (exp) => {
  cookies.set(BROWSER_STORAGE.TOKEN_EXPIRY_TIME, exp, {
    path: "/",
    maxAge: BROWSER_STORAGE.COOKIE_EXPIRY_TIME,
  });
};

export const setUserDetails = (userDetails) => {
  // console.log("userDetails", userDetails)
  // cookies.set(BROWSER_STORAGE.USERS_DETAILS, JSON.stringify(userDetails), {
  //   path: "/",
  //   maxAge: BROWSER_STORAGE.COOKIE_EXPIRY_TIME,
  // });
  localStorage.setItem(BROWSER_STORAGE.USERS_DETAILS, JSON.stringify(userDetails));
};

export const setUserPermissions = (userPermissions) => {
  localStorage.setItem(BROWSER_STORAGE.USER_PERMISSIONS, userPermissions);
  // cookies.set(
  //   BROWSER_STORAGE.USER_PERMISSIONS,
  //   JSON.stringify(userPermissions),
  //   {
  //     path: "/",
  //     maxAge: BROWSER_STORAGE.COOKIE_EXPIRY_TIME,
  //   }
  // );
};

export const getAccessToken = () => cookies.get(BROWSER_STORAGE.ACCESS_TOKEN);

export const getTokenExpiry = () =>
  cookies.get(BROWSER_STORAGE.TOKEN_EXPIRY_TIME);

// export const getUserDetails = () => cookies.get(BROWSER_STORAGE.USERS_DETAILS);
export const getUserDetails = () => localStorage.getItem(BROWSER_STORAGE.USERS_DETAILS);

export const getUserPermissions = () =>
  cookies.get(BROWSER_STORAGE.USER_PERMISSIONS);

export const removeAccessToken = () => {
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN, { path: "/" });
  cookies.remove(BROWSER_STORAGE.USERS_DETAILS, { path: "/" });
  cookies.remove(BROWSER_STORAGE.TOKEN_EXPIRY_TIME, { path: "/" });
};

export const removeOnlyAccessToken = () => {
  cookies.remove(BROWSER_STORAGE.ACCESS_TOKEN, { path: "/" });
  cookies.remove(BROWSER_STORAGE.TOKEN_EXPIRY_TIME, { path: "/" });
};

// removeUserStorage();
