import { Card, CardContent, Grid } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import ForgotPasswordImg from "../../../assets/images/forgot_pass.png";
import FormValidator from "../../../services/FormValidator";
import CustomInput from "../../components/Input";
import CustomButton from "../../components/Button";
import BackArrow from "../../../assets/icons/left-arrow.svg";
import { postAuthApi } from "../../../webApis/apiResource";
import { successToast } from "../../../modules/actions/toast.action";
import { webApiCheck } from "../../../services/commonMethods";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
    };

    this.rules = {
      email: { required: true, email: true },
    };

    this.btns = [
      {
        type: "submit",
        btnProps: {
          method: this.submit,
          text: "Submit",
          variant: "contained",
          classes: {
            root: "login-submit-btn",
            disabled: "disabled-btn disabled-login-btn",
          },
        },
      },
    ];
  }

  handleChange = (ev) => {
    this.setState({
      [ev.target.name]: ev.target.value,
    });
  };

  submit = async () => {
    const payload = {
      action: "forgot_password",
      email: this.state.email,
    };
    const response = await postAuthApi("/user-management", { payload });
    if (webApiCheck(response)) {
      this.props.successToast({
        message: "Email sent successfully!",
      });
    }
  };

  render() {
    return (
      <div className="unauth-content bgNone">
        <Grid container direction="row">
          <Grid item lg={7}>
            <img
              src={ForgotPasswordImg}
              className="login-image forgot-password-image"
              alt="Forgot Password Image"
            />
          </Grid>
          <Grid item lg={5}>
            <Card className="login-card ">
              <CardContent>
                <FormValidator
                  rules={this.rules}
                  btns={this.btns}
                  data={this.state}
                >
                  <div
                    className="card-title"
                    style={{
                      marginTop: "48px",
                    }}
                  >
                    Forgot Password
                  </div>
                  <span className="card-subtitle">
                    Please enter registered email you used at the time of
                    registration to get the password reset instructions.
                  </span>
                  <CustomInput
                    formControlProps={{
                      fullWidth: true,
                      margin: "normal",
                      variant: "filled",
                    }}
                    inputProps={{
                      value: this.state.email,
                      onChange: this.handleChange,
                      placeholder: "Email",
                      name: "email",
                      className: "input-login",
                      required: true,
                      type: "email",
                    }}
                  />
                  <CustomButton
                    type="button"
                    btnProps={{
                      text: "Back to Login",
                      startIcon: (
                        <img
                          src={BackArrow}
                          alt="Back to Login Image"
                          className="padlock-icon"
                          style={{ transform: "translateX(-2px)" }}
                        />
                      ),
                      className: "forgot-password-btn-link float-right",
                    }}
                    onClick={() => this.props.history.push("/login")}
                  />
                </FormValidator>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  successToast: (obj) => dispatch(successToast(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
