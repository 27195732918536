import {
  FETCH_CUSTOMER_LIST,
  RESET_CUSTOMER_LIST,
} from "../../constants/action.constants";

//Storage for data.
const initialState = {
  list: [],
};

//Controller for the storage.
const customerListReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMER_LIST:
      return { list: action.payload }
    case RESET_CUSTOMER_LIST:
      return initialState;
    default:
      return state;
  }
};

export default customerListReducer;
