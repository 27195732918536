import React, { useEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Grid } from "@material-ui/core";
import { withRouter } from "react-router-dom";
import DeletePopIcon from "../../../assets/images/delete_popup_icon.svg";
import CustomButton from "../Button";

function LogOutWarning(props) {
  const [show, setShow] = useState(false);
  const { open, onClose } = props;

  useEffect(() => {
    setShow(open);
  }, [open]);

  return (
    <Dialog
      open={show}
      onClose={onClose}
      classes={{ paper: "delete-modal" }}
      fullWidth={true}
    >
      <DialogTitle className="text-center">
        <img
          src={DeletePopIcon}
          alt="delete-popup image"
          className="delete-popup-icon"
        />
      </DialogTitle>
      <DialogContent className="text-center delete-dialog-content">
        <h3 style={{ fontSize: "28px" }}>Ideal State Warning</h3>
        <span>You will be logged out soon.</span>
        <br />
        <br />
        <Grid container>
          <Grid item lg={12} md={12} sm={12} className="text-center mt-4">
            <CustomButton
              type="button"
              btnProps={{
                text: "Don't Log Out",
                className: "cancel-btn dont-log-out",
                variant: "contained",
                onClick: onClose,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default withRouter(LogOutWarning);
