import React, { Component } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import FeedbackGuestButton from "../pages/components/FeedbackScreen/FeedbackGuestButton/FeedbackGuestButton";
import ForgotPassword from "../pages/containers/ForgotPassword";
import Login from "../pages/containers/Login";
import AppContainer from "./AppContainer";

class UnAuthRoutes extends Component {
  render() {
    return (
      <Router>
        <AppContainer>
          <Switch>
            <Route exact path="/login" component={Login} />
            {/* <Route exact path="/forgot-password" component={ForgotPassword} /> */}
            <Redirect from="/" to="/login" />
          </Switch>
        </AppContainer>
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UnAuthRoutes);
