import { FETCH_ORDER, RESET_ORDER } from "../../constants/action.constants";

const initialState = {
  data: {},
};

const orderViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ORDER:
      return { data: action.payload };
    case RESET_ORDER:
      return initialState;
    default:
      return state;
  }
};

export default orderViewReducer;
